<template>
  <f-page :title="$t('labels.fileDetail')">
    <f-tabs card pills>
      <f-tab :title="$t('labels.informations')">
        <TabGeneralInfo v-if="fileData.id" :fileData="fileData" />
      </f-tab>
      <f-tab :title="$t('labels.attachments')">
        <TabAttachments v-if="fileData.id" :fileData="fileData" />
      </f-tab>
    </f-tabs>
  </f-page>
</template>
<script>
import { networkTypes } from '@/data/enums'
import TabGeneralInfo from './TabGeneralInfo'
import TabAttachments from './TabAttachments'
export default {
  components: {
    TabGeneralInfo,
    TabAttachments
  },
  data() {
    return {
      fileData: {
        type: Object
      }
    }
  },
  provide() {
    return {
      getNetworkPricing: this.getNetworkPricing,
      calculatePriceWithKm: this.calculatePriceWithKm
    }
  },
  async mounted() {
    const file = await this.$http.get(
      `/networkApp/file/${this.$route.params.no}`
    )
    this.fileData = file || this.$router.push({ path: '/' })
  },
  methods: {
    async getNetworkPricing() {
      let networkPricing = null
      if (
        this.fileData.fileService.networkId &&
        this.fileData.fileService.serviceType &&
        this.fileData.fileService.serviceType.networkTypeId &&
        [
          networkTypes.CEKICI,
          networkTypes.ARAC_KIRALAMA,
          networkTypes.OTO_TAMIR_SERVISI
        ].includes(this.fileData.fileService.serviceType.networkTypeId)
      ) {
        networkPricing = await this.$http.post(
          '/networkApp/files/services/networkPricing',
          {
            networkId: this.fileData.fileService.networkId,
            networkTypeId: this.fileData.fileService.serviceType.networkTypeId,
            vehicleTypeId: this.fileData.fileVehicle.vehicleModel.vehicleTypeId,
            vehicleSegmentId: this.fileData.fileService.vehicleSegmentId,
            fileClaimDateTime: this.fileData.fileClaim.dateTime,
            fileClaimCityId: this.fileData.fileClaim.county.cityId
          }
        )

        return networkPricing
      }
    },
    calculatePriceWithKm(networkPricing, km) {
      if (
        !networkPricing ||
        !networkPricing.limitKmPrice ||
        !km ||
        parseInt(km) === 0
      ) {
        return 0
      }

      let calculatedPrice = parseFloat(networkPricing.limitKmPrice)

      if (networkPricing.limitKm && networkPricing.outOfLimitPricePerKm) {
        const outOfLimitKm = parseInt(km) - parseInt(networkPricing.limitKm)

        if (outOfLimitKm > 0) {
          calculatedPrice +=
            outOfLimitKm * parseFloat(networkPricing.outOfLimitPricePerKm)
        }
      }

      return calculatedPrice
    }
  }
}
</script>
<style>
.table-responsive {
  width: 50%;
}
</style>
