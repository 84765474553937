<template>
  <f-row md="12">
    <f-col :md="craneValuesEnabled ? 6 : 12">
      <f-row>
        <f-col md="6">
          <f-input
            :label="$t('labels.realKm')"
            v-model="fileData.fileService.realKm"
            type="number"
            @blur="setCalculatedRealPrice"
            :disabled="serviceStatus"
          />
        </f-col>
        <f-col md="6">
          <f-input
            :label="'KDV dahil tutar'"
            :value="realPrice"
            type="number"
            :disabled="true"
          />
        </f-col>
        <f-col md="12">
          <f-input
            :label="$t('labels.extraPrice')"
            v-model="fileData.fileService.extraPrice"
            type="number"
            step="any"
            @blur="setAllCalculatedPrices"
            :disabled="serviceStatus"
          />
        </f-col>
        <f-col md="12">
          <f-input
            :label="$t('labels.extraPriceReason')"
            v-model="fileData.fileService.extraPriceReason"
            multiline
            :disabled="serviceStatus"
          />
        </f-col>
      </f-row>
    </f-col>
    <f-col v-if="craneValuesEnabled" md="6">
      <f-row>
        <f-col>
          <f-switch
            :disabled="serviceStatus"
            v-if="
              vehicleTypeCraneValues.includes(
                fileData.fileVehicle.vehicleModel.vehicleTypeId
              )
            "
            label="Ahtapot Durumu"
            v-model="fileData.fileService.hasCrane"
            @change="setAllCalculatedPrices"
            left-label
          />
        </f-col>
        <f-col>
          <f-switch
            :disabled="serviceStatus"
            v-if="
              vehicleTypeExtraLongValues.includes(
                fileData.fileVehicle.vehicleModel.vehicleTypeId
              )
            "
            label="Ekstra Uzun Araç"
            v-model="fileData.fileService.hasExtraLongVehicle"
            left-label
          />
        </f-col>
      </f-row>
    </f-col>
  </f-row>
</template>

<script>
import {
  vehicleTypes,
  vehicleTypeCrane,
  vehicleTypeExtraLong
} from '@/data/enums'

export default {
  props: ['fileData'],
  data () {
    return {
      payload: {
        type: Object
      },
      vehicleTypeCraneValues: Object.values(vehicleTypeCrane),
      vehicleTypeExtraLongValues: Object.values(vehicleTypeExtraLong),
      networkPricing: null
    }
  },
  inject: ['getNetworkPricing', 'serviceStatus'],
  async mounted () {
    this.networkPricing = await this.getNetworkPricing()
  },
  computed: {
    realPrice () {
      return this.networkPricing
        ? this.calculatePrice(this.fileData.fileService.realKm)
        : 0
    },
    craneValuesEnabled () {
      return (
        this.vehicleTypeCraneValues.includes(
          this.fileData.fileVehicle.vehicleModel.vehicleTypeId
        ) ||
        this.vehicleTypeExtraLongValues.includes(
          this.fileData.fileVehicle.vehicleModel.vehicleTypeId
        )
      )
    }
  },
  methods: {
    calculatePrice (km) {
      let calculatedPrice = this.calculatePriceWithKm(this.networkPricing, km)
      const extraPrice = parseFloat(this.fileData.fileService.extraPrice || 0)
      if (
        this.fileData.fileService.hasCrane === true &&
        this.networkPricing &&
        this.networkPricing.towExtra
      ) {
        calculatedPrice += parseFloat(
          this.networkPricing.towExtra.cranePrice || 0
        )
      }
      if (
        this.fileData.fileVehicle.vehicleModel.vehicleTypeId ===
          vehicleTypes.HAFIF_TICARI_UZUN_SASE &&
        this.fileData.fileService.hasExtraLongVehicle === true &&
        this.networkPricing.towExtra
      ) {
        calculatedPrice += parseFloat(
          this.networkPricing.towExtra.extraLongPrice || 0
        )
      }
      if (this.fileData.fileService.isInterrupted === true) {
        calculatedPrice = calculatedPrice / 2
      }
      calculatedPrice += extraPrice
      return calculatedPrice
    },
    setCalculatedRealPrice () {
      this.fileData.fileService.realPrice = this.realPrice
    },
    setAllCalculatedPrices () {
      this.setCalculatedRealPrice()
    },
    calculatePriceWithKm (networkPricing, km) {
      if (
        !networkPricing ||
        !networkPricing.limitKmPrice ||
        !km ||
        parseInt(km) === 0
      ) {
        return 0
      }

      let calculatedPrice = parseFloat(networkPricing.limitKmPrice)

      if (networkPricing.limitKm && networkPricing.outOfLimitPricePerKm) {
        const outOfLimitKm = parseInt(km) - parseInt(networkPricing.limitKm)

        if (outOfLimitKm > 0) {
          calculatedPrice +=
            outOfLimitKm * parseFloat(networkPricing.outOfLimitPricePerKm)
        }
      }
      return calculatedPrice
    }
  }
}
</script>

<style scoped></style>
