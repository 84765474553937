<template>
  <div>
    <f-row>
      <f-col md="12">
        <f-col md="12">
          <f-input
            :label="$t('labels.realizePayment')"
            v-model="fileData.fileService.realPrice"
            type="number"
            :disabled="serviceStatus"
          />
        </f-col>
        <f-col md="12">
          <f-input
            multiline
            :label="$t('labels.description')"
            v-model="fileData.fileService.description"
            :disabled="serviceStatus"
          />
        </f-col>
      </f-col>
    </f-row>
  </div>
</template>

<script>
export default {
  props: {
    fileData: {
      type: Object
    }
  },
  data() {
    return {
      payload: {
        type: Object
      }
    }
  },
  inject: ['serviceStatus']
}
</script>

<style scoped></style>
