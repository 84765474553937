var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-row',{attrs:{"md":"12"}},[_c('f-col',{attrs:{"md":_vm.craneValuesEnabled ? 6 : 12}},[_c('f-row',[_c('f-col',{attrs:{"md":"6"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.realKm'),"type":"number","disabled":_vm.serviceStatus},on:{"blur":_vm.setCalculatedRealPrice},model:{value:(_vm.fileData.fileService.realKm),callback:function ($$v) {_vm.$set(_vm.fileData.fileService, "realKm", $$v)},expression:"fileData.fileService.realKm"}})],1),_c('f-col',{attrs:{"md":"6"}},[_c('f-input',{attrs:{"label":'KDV dahil tutar',"value":_vm.realPrice,"type":"number","disabled":true}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.extraPrice'),"type":"number","step":"any","disabled":_vm.serviceStatus},on:{"blur":_vm.setAllCalculatedPrices},model:{value:(_vm.fileData.fileService.extraPrice),callback:function ($$v) {_vm.$set(_vm.fileData.fileService, "extraPrice", $$v)},expression:"fileData.fileService.extraPrice"}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.extraPriceReason'),"multiline":"","disabled":_vm.serviceStatus},model:{value:(_vm.fileData.fileService.extraPriceReason),callback:function ($$v) {_vm.$set(_vm.fileData.fileService, "extraPriceReason", $$v)},expression:"fileData.fileService.extraPriceReason"}})],1)],1)],1),(_vm.craneValuesEnabled)?_c('f-col',{attrs:{"md":"6"}},[_c('f-row',[_c('f-col',[(
            _vm.vehicleTypeCraneValues.includes(
              _vm.fileData.fileVehicle.vehicleModel.vehicleTypeId
            )
          )?_c('f-switch',{attrs:{"disabled":_vm.serviceStatus,"label":"Ahtapot Durumu","left-label":""},on:{"change":_vm.setAllCalculatedPrices},model:{value:(_vm.fileData.fileService.hasCrane),callback:function ($$v) {_vm.$set(_vm.fileData.fileService, "hasCrane", $$v)},expression:"fileData.fileService.hasCrane"}}):_vm._e()],1),_c('f-col',[(
            _vm.vehicleTypeExtraLongValues.includes(
              _vm.fileData.fileVehicle.vehicleModel.vehicleTypeId
            )
          )?_c('f-switch',{attrs:{"disabled":_vm.serviceStatus,"label":"Ekstra Uzun Araç","left-label":""},model:{value:(_vm.fileData.fileService.hasExtraLongVehicle),callback:function ($$v) {_vm.$set(_vm.fileData.fileService, "hasExtraLongVehicle", $$v)},expression:"fileData.fileService.hasExtraLongVehicle"}}):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }