<template>
  <f-img-lazy :src="data.url" alt="Image 1"></f-img-lazy>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
}
</script>
