<template>
  <div>
    <f-row md="12">
      <f-col md="6">
        <f-input
          :label="$t('labels.deliveryDateRentalCar')"
          type="datetime-local"
          v-model="fileData.fileService.deliveryDate"
          :rules="
            fileData.fileService.networkId && !fileData.fileService.deliveryDate
              ? 'required'
              : ''
          "
          :disabled="serviceStatus"
        />
      </f-col>
      <f-col md="6">
        <f-input
          :label="$t('labels.deliveryBackDateRentalCar')"
          type="datetime-local"
          :value="deliveryBackDate"
          :disabled="true"
        />
      </f-col>
      <f-col md="6">
        <f-select
          :label="$t('labels.vehicleSegment')"
          v-model="fileData.fileService.vehicleSegmentId"
          data-url="/select/vehicleSegments"
          @select="onSelectVehicleType"
          :disabled="serviceStatus"
        />
      </f-col>
      <f-col md="6">
        <f-input
          :label="'KDV dahil tutar'"
          :value="realPrice"
          type="number"
          :disabled="true"
        />
      </f-col>
      <f-col md="6">
        <f-input
          :label="$t('labels.rentalCarDuration')"
          v-model="fileData.fileService.repairDay"
          type="number"
          :disabled="serviceStatus"
          step="any"
          @blur="setCalculatedRealPrice"
        />
      </f-col>
      <f-col md="6">
        <f-input
          :label="$t('labels.dropKm')"
          v-model="fileData.fileService.dropKm"
          type="number"
          @blur="setCalculatedRealPrice"
          :disabled="serviceStatus"
        />
      </f-col>
    </f-row>
  </div>
</template>

<script>
export default {
  props: {
    fileData: {
      type: Object
    },
    segmentTypes: {
      type: Array
    }
  },
  data() {
    return {
      networkPricing: null
    }
  },
  inject: ['getNetworkPricing', 'serviceStatus'],
  computed: {
    realPrice() {
      let calculatedPrice = parseFloat(
        this.fileData.fileService.extraPrice || 0
      )
      if (this.networkPricing && this.networkPricing.dailyPrice) {
        calculatedPrice +=
          parseInt(this.fileData.fileService.repairDay) *
          parseFloat(this.networkPricing.dailyPrice)

        if (
          this.fileData.fileService.dropKm &&
          this.networkPricing.dropPricePerKm
        ) {
          calculatedPrice +=
            parseInt(this.fileData.fileService.dropKm) *
            parseFloat(this.networkPricing.dropPricePerKm)
        }
      }
      return calculatedPrice
    },
    deliveryBackDate() {
      if (this.fileData.fileService.repairDay) {
        const deliveryDate = new Date(this.fileData.fileService.deliveryDate)
        deliveryDate.setDate(
          deliveryDate.getDate() + parseInt(this.fileData.fileService.repairDay)
        )
        return deliveryDate
      } else {
        return null
      }
    }
  },
  async mounted() {
    if (this.fileData.fileService.vehicleSegmentId) {
      this.networkPricing = await this.getNetworkPricing()
    }
    if (
      !this.fileData.fileService.deliveryCountyId &&
      this.fileData.fileClaim.countyId
    ) {
      this.fileData.fileService.deliveryCountyId = this.fileData.fileClaim.countyId
      this.fileData.fileService.deliveryCounty = this.fileData.fileClaim.county
    }
  },
  methods: {
    setCalculatedRealPrice() {
      this.fileData.fileService.realPrice = this.realPrice
    },
    async onSelectVehicleType() {
      this.networkPricing = await this.getNetworkPricing()
    }
  }
}
</script>

<style scoped></style>
