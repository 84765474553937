<template>
  <f-card>
    <f-row>
      <f-col md="12">
        <f-upload
          drag-drop
          multiple
          accept="*/*"
          :placeholder="$t('labels.uploadAttachment')"
          @input="uploadAttachment"
        />
      </f-col>

      <f-col md="12">
        <f-datatable
          ref="attachmentDatatable"
          :columns="fileAttachmentColumns"
          :data-url="`/networkApp/files/${fileData.fileGroupId}/attachments`"
          :features="{
            delete: false,
            pagination: false,
            fullscreen: false,
            showColumns: false
          }"
        ></f-datatable>
      </f-col>
    </f-row>
    <f-modal
      ref="attachmentModal"
      id="attachmentModal"
      size="m"
      :title="photoData.name"
      submitDisabled
    >
      <AttachmentModal :data="photoData" />
    </f-modal>
  </f-card>
</template>

<script>
import { fileAttachmentColumns } from '@/data/columns'
import AttachmentModal from './ModalAttachment'

export default {
  components: {
    AttachmentModal
  },
  props: ['fileData'],
  data() {
    return {
      fileAttachmentColumns,
      photoData: {}
    }
  },
  methods: {
    async loadAttachments() {
      this.$refs.attachmentDatatable.refresh()
    },
    async uploadAttachment(attachmentList) {
      for (const attachment of attachmentList) {
        const formData = new FormData()
        formData.append('attachment', attachment)
        await this.$http.post(
          `/networkApp/files/${this.fileData.id}/attachments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      }
      await this.loadAttachments()
    },
    onClickAction(param) {
      this.photoData = param
      this.$refs.attachmentModal.show()
    }
  }
}
</script>
