<template>
  <div>
    <f-card>
      <f-row>
        <div class="table-responsive border">
          <table class="table row table-borderless w-100 m-0">
            <tbody class="p-0 w-100">
              <tr class="w-100 d-flex">
                <td class="w-100">
                  <strong>{{ $t('labels.serviceAssignmentDate') }} :</strong>
                  {{
                    fileData &&
                    fileData.fileService &&
                    fileData.fileService.networkAssignedDate
                      ? getDateTime(
                          fileData.fileService.networkAssignedDate,
                          'dd/MM/yyyy HH:mm'
                        )
                      : ' - '
                  }}
                </td>
              </tr>
              <tr class="w-100 d-flex">
                <td class="w-100">
                  <strong>{{ $t('labels.fileNo') }} :</strong>
                  {{ checkValueExist(fileData.no) }}
                </td>
              </tr>
              <template v-if="fileData.fileVehicle">
                <tr class="w-100 d-flex">
                  <td class="w-100">
                    <strong>{{ $t('labels.vehicleBrand') }} :</strong>
                    {{
                      checkValueExist(
                        fileData.fileVehicle.vehicleModel.vehicleBrand.name
                      )
                    }}
                  </td>
                </tr>
                <tr class="w-100 d-flex">
                  <td class="w-100">
                    <strong>{{ $t('labels.vehicleModel') }} :</strong>
                    {{
                      checkValueExist(fileData.fileVehicle.vehicleModel.name)
                    }}
                  </td>
                </tr>
                <tr class="w-100 d-flex">
                  <td class="w-100">
                    <strong>{{ $t('labels.vehiclePlate') }} :</strong>
                    {{ checkValueExist(fileData.fileVehicle.plateNo) }}
                  </td>
                </tr>
              </template>
              <template v-if="fileServiceType !== 'serviceTypeRentalCar'">
                <tr class="w-100 d-flex" v-if="fileData.fileVehicle">
                  <td class="w-100">
                    <strong
                      >{{ $t('labels.vehicleRegistrationType') }} :</strong
                    >
                    {{
                      checkValueExist(
                        fileData.fileVehicle.vehicleModel.vehicleType.name
                      )
                    }}
                  </td>
                </tr>
                <tr class="w-100 d-flex">
                  <td class="w-100">
                    <strong class="mr-1">{{ $t('labels.claim') }} :</strong>
                    {{ checkValueExist(fileData.fileClaim.claimType.name) }}
                  </td>
                </tr>
              </template>
              <tr class="w-100 d-flex">
                <td class="w-100">
                  <strong>{{ $t('labels.serviceType') }} :</strong>
                  {{ checkValueExist(fileData.fileService.serviceType.name) }}
                </td>
              </tr>
              <tr class="w-100 d-flex">
                <td class="w-100">
                  <strong>{{ $t('labels.serviceStatus') }} :</strong>
                  {{
                    fileData.fileService && fileData.fileService.status
                      ? $t(`labels.${fileData.fileService.status}`)
                      : ' - '
                  }}
                </td>
              </tr>
              <tr
                class="w-100 d-flex"
                v-if="
                  fileData.fileService && fileData.fileService.isInterrupted
                "
              >
                <td class="w-100">
                  <strong style="color:red"
                    >{{ $t('labels.towInterrupted') }}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive border ">
          <table class="table row table-borderless w-100 m-0">
            <tbody class="p-0 w-100">
              <tr class="w-100">
                <td class="w-100">
                  <strong
                    >{{
                      fileServiceType !== 'serviceTypeRentalCar'
                        ? $t('labels.city')
                        : $t('labels.deliveryTargetCity')
                    }}
                    :
                  </strong>
                  {{
                    fileData.fileClaim.county
                      ? checkValueExist(fileData.fileClaim.county.city.name)
                      : ' - '
                  }}
                </td>
              </tr>
              <tr class="w-100">
                <td class="w-100">
                  <strong>
                    {{
                      fileServiceType !== 'serviceTypeRentalCar'
                        ? $t('labels.county')
                        : $t('labels.deliveryTargetCounty')
                    }}
                  </strong>
                  {{
                    fileData.fileClaim.county
                      ? fileData.fileClaim.county.name
                      : ' - '
                  }}
                </td>
              </tr>
              <template v-if="fileServiceType !== 'serviceTypeRentalCar'">
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.address') }} :</strong>
                    {{ checkValueExist(fileData.fileClaim.address) }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.gpsCoords') }} :</strong>
                    {{ checkValueExist(fileData.fileClaim.gpsCoords) }}
                  </td>
                </tr>
              </template>
              <template v-if="fileServiceType === 'serviceTypeRentalCar'">
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.deliveryReturnCity') }} :</strong>
                    {{
                      checkValueExist(
                        fileData.fileService.deliveryCounty.city.name
                      )
                    }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.deliveryReturnCounty') }} :</strong>
                    {{
                      checkValueExist(fileData.fileService.deliveryCounty.name)
                    }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.customerVehicleSegment') }} :</strong>
                    {{
                      fileData.fileService.customerVehicleSegment
                        ? fileData.fileService.customerVehicleSegment.name
                        : ' - '
                    }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.notes') }} :</strong>
                    {{ checkValueExist(fileData.fileService.description) }}
                  </td>
                </tr>
              </template>
              <template v-if="fileServiceType === 'serviceTypeTow'">
                <tr class="w-100">
                  <td class="w-100">
                    <strong class="font-weight-bolder">
                      <u>{{ $t('labels.serviceInfo') }} :</u>
                    </strong>
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.serviceName') }} :</strong>
                    {{ checkValueExist(fileData.fileRepairService.name) }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.city') }} :</strong>
                    {{
                      fileData.fileRepairService &&
                      fileData.fileRepairService.county
                        ? checkValueExist(
                            fileData.fileRepairService.county.city.name
                          )
                        : ' - '
                    }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.county') }} :</strong>
                    {{
                      fileData.fileRepairService &&
                      fileData.fileRepairService.county
                        ? checkValueExist(
                            fileData.fileRepairService.county.name
                          )
                        : ' - '
                    }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.address') }} :</strong>
                    {{ checkValueExist(fileData.fileRepairService.address) }}
                  </td>
                </tr>
                <tr class="w-100">
                  <td class="w-100">
                    <strong>{{ $t('labels.gpsCoords') }} :</strong>
                    {{ checkValueExist(fileData.fileRepairService.gpsCoords) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </f-row>
    </f-card>
    <f-card>
      <component
        v-bind:is="
          serviceTypeComponents[fileServiceType].component ||
            serviceTypeComponents['networkOther']
        "
        :fileData="fileData"
      />
      <f-col
        md="12"
        class="d-flex justify-content-center"
        v-if="!serviceStatus"
      >
        <f-button :label="$t('labels.submit')" @click="saveFile" />
      </f-col>
    </f-card>
  </div>
</template>
<script>
import { getDateTime } from '@/utils'
import {
  serviceTypeTow,
  serviceTypeRentalCar,
  fileServiceStatus
} from '@/data/enums'
import FileServiceTow from './fileServiceContent/FileServiceTow'
import FileServiceRentalCar from './fileServiceContent/FileServiceRentalCar'
import FileServiceOther from './fileServiceContent/FileServiceOther'

export default {
  props: ['fileData'],
  data () {
    return {
      getDateTime,
      serviceTypeComponents: {
        serviceTypeTow: {
          serviceTypeIds: Object.values(serviceTypeTow),
          component: FileServiceTow
        },
        serviceTypeRentalCar: {
          serviceTypeIds: Object.values(serviceTypeRentalCar),
          component: FileServiceRentalCar
        },
        serviceTypeOther: {
          // "serviceTypeIds" key must be assigned empty array to avoid error from fileServiceType computed property
          serviceTypeIds: [],
          component: FileServiceOther
        }
      }
    }
  },
  provide () {
    return {
      serviceStatus: this.serviceStatus
    }
  },
  computed: {
    fileServiceType () {
      const serviceType = Object.keys(this.serviceTypeComponents).find(type =>
        this.serviceTypeComponents[type].serviceTypeIds.includes(
          this.fileData.fileService.serviceTypeId
        )
      )
      return serviceType || 'serviceTypeOther'
    },
    serviceStatus () {
      const valid = [fileServiceStatus.OPENED]
      return !valid.includes(this.fileData.fileService.status)
    }
  },
  methods: {
    checkValueExist (value) {
      return value || ' - '
    },
    async getSegmentTypes () {
      const getTypes = await this.$http.get('/select/vehicleSegments')
      this.segmentTypes = getTypes
    },
    async saveFile () {
      const isTow = this.fileServiceType === 'serviceTypeTow'
      const isRentalCar = this.fileServiceType === 'serviceTypeRentalCar'
      await this.$http.put(`/networkApp/files/${this.fileData.id}`, {
        file: this.fileData,
        isTow,
        isRentalCar
      })
      this.$flex.notification.success(this.$t('messages.saveSuccess'))
    }
  }
}
</script>

<style>
.no-margin > fieldset {
  margin: 0;
}
</style>
